import { useYupChangeLocale } from '@Core/Helpers/Yup'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CoreConfirmProvider } from '@Core/Components/Confirm/CoreConfirm'
import { SOCIAL_AUTH_CLIENT } from 'env'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import stylisRTLPlugin from 'stylis-plugin-rtl'
import { useMultiLanguageContext } from '../MultiLanguageProvider'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
// import PropTypes from 'prop-types'
import { LoadingFullPage } from '@Core/Components/CoreLoading'
import ExamResultDialogProvider from '@Core/Components/Common/ButtonShowResult/ExamResultDialogProvider'
import { getQueryUrlObject } from '@Core/Helpers/Url'
import { clearCache, useRequest } from 'ahooks'
import {
	clearSession,
	LOCAL_STORAGE,
	SESSION_STORAGE,
	setAuthDataSesstion,
	setAuthTokenSession,
	setCampaignSale,
	setDataSession,
	setSystemSettingSession
} from '@Core/Helpers/Session'
import systemService from '@App/Studio/services/systemService'
import Cookies from 'js-cookie'
import CoreBannerMarketingProvider from '../CoreBannerMarketingProvider'

const emotionCacheOptions = {
	rtl: {
		key: 'muirtl',
		stylisPlugins: [stylisRTLPlugin],
		insertionPoint: document.getElementById('emotion-insertion-point')
	},
	ltr: {
		key: 'muiltr',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	}
}

const CoreAppContext = React.createContext({
	systemConfig: {}
})

export const useCoreAppContext = () => React.useContext(CoreAppContext)

const CoreAppProvider = props => {
	useYupChangeLocale()

	const { languageCacheStyle } = useMultiLanguageContext()
	const [loading, setLoading] = useState(true)
	const [systemConfig, setSystemConfig] = useState()

	const { data } = useRequest(systemService.getGeneralConfig, {
		cacheKey: 'getGeneralConfig',
		cacheTime: 1000 * 60 * 60,
		staleTime: 1000 * 60 * 60,
		onSuccess: res => {
			if (res) {
				setSystemSettingSession(res)
				setSystemConfig(res)
			}
		}
	})

	useEffect(() => {
		setCampaignSale()
		const query = getQueryUrlObject()

		if (query?.open) {
			setDataSession(LOCAL_STORAGE, 'app_open', 'web-view')
		}

		if (query?.via || query?.utm_source) {
			Cookies.set('marketing', JSON.stringify(query), {
				expires: 30,
				secure: true,
				sameSite: 'lax'
				// domain: '.eduquiz.io.vn'
			})
		}

		if (query?.access_token) {
			clearCache()
			clearSession()
			const data = {
				access_token: query?.access_token,
				token_type: 'Bearer'
			}
			setAuthTokenSession(data)
			// return data
		}

		// if (query?.backUrl) {
		// 	setDataSession(SESSION_STORAGE, 'backUrl', query.backUrl)
		// }
		setLoading(false)
	}, [])

	return (
		<CoreBannerMarketingProvider>
			<CoreAppContext.Provider value={{ systemConfig }}>
				<GoogleOAuthProvider clientId={SOCIAL_AUTH_CLIENT.google_client_id}>
					<CacheProvider value={createCache(emotionCacheOptions[languageCacheStyle])}>
						<ExamResultDialogProvider>
							<CoreConfirmProvider>{loading ? null : props.children}</CoreConfirmProvider>
						</ExamResultDialogProvider>
						<ToastContainer
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<LoadingFullPage />
					</CacheProvider>
				</GoogleOAuthProvider>
			</CoreAppContext.Provider>
		</CoreBannerMarketingProvider>
	)
}

// CoreAppProvider.defaultProps = {}

// CoreAppProvider.propTypes = {}

export default React.memo(CoreAppProvider)
