const basePath = 'order'
export const ROUTER_ORDER = {
	checkout: `${basePath}/checkout`,
	cart: `${basePath}/cart`,
	plan: `${basePath}/plan`
}

export const TRANSLATE_ORDER = {
	checkout: `${basePath}/checkout`,
	cart: `${basePath}/cart`
}
