import BaseService from '@Core/axios/BaseService'

class UserExam extends BaseService {
	BASE_ENDPOINT = '/quizexam/studio/api/v1/user/exams'

	constructor(props) {
		super(props)
		this.setRequest()
	}

	// lấy danh sách bài tập của user
	getExamHomeworkUser = params => {
		const endpoint = 'examroom/studio/api/v1/user/exam-rooms'

		return this.request.get(endpoint, { params })
	}

	getFavoriteExams = () => {
		const endpoint = '/quizexam/studio/api/v1/user/exams/my-favorited'
		return this.request.get(endpoint)
	}

	getStatisExamRoom = () => {
		const endpoint = '/examroom/studio/api/v1/user/exam-rooms/statis-my-room'
		return this.withCache(() => this.request.get(endpoint), endpoint)
	}
}

const userExamService = new UserExam()

export default userExamService
